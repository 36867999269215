import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Logo from "../images/pool_logo.svg"

import { yellow, gray, black, white } from "../utils/colors"

import ContactIcon from "../images/icon_mail.svg"

const logo = css({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    "@media(max-width: 639px)": {
        transform: "translateY(-50%)",
        left: 16,
        width: 86
    }
})

const conatctButton = css({
    position: "absolute",
    right: 32,
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
    "& p": {
        backgroundColor: yellow,
        color: black,
        fontSize: 12,
        padding: "2px 6px",
        margin: "4px 0 0 0"
    },
    "@media(max-width: 639px)": {
        right: 16,
    }
})

export default () => (
    <div css={css`
        height: 120px;
        position: relative;
        overflow: hidden;
    `}>
        <Link to="/">
          <img css={logo} src={Logo} alt="POOL Inc."/>
        </Link>
    </div>
)
