import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { Container, Row, Col } from "reactstrap"

import BlackLogoImage from "../images/pool_logo.svg"

const footer = css ({
    padding: "65px",
    backgroundColor: "#EFEFEF",
    textAlign: "center",
    "& img": {
        margin: "1rem auto"
    },
    "& p": {
        margin: "1rem auto"
    },
    "copyright": {
        fontSize: 12
    }
})

export default () => (
    <Container fluid>
        <Row>
            <Col css={footer}>
                <img src={BlackLogoImage} alt="POOL"/>
                <p className="halant"><b>Tokyo / Japan</b></p>
                <p className="halant copyright">©️ POOL Inc. All Rights Reserved.</p>
            </Col>
        </Row>
    </Container>
)
